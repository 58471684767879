@import 'variables';

.om-icon {
    display: inline-block;
    background-position: center;
    width: $default-icon-width;
    height: $default-icon-height;
    vertical-align: top;

    @supports (mask-image: url()) {
      background-color: $default-icon-color;
      mask-position: center;
    }

    &.icon-close {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eicons%20%2F%20icn-close%3C%2Ftitle%3E%3Cdefs%3E%3Cpath%20d%3D%22M9%207.94L16.47.47l1.06%201.06L10.06%209l7.47%207.47-1.06%201.06L9%2010.06l-7.47%207.47-1.06-1.06L7.94%209%20.47%201.53%201.53.47%209%207.94z%22%20id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cpath%20d%3D%22M0%200h18v18H0z%22%20mask%3D%22url(%23b)%22%20fill%3D%22%23434343%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        @supports (mask-image: url()) {
          background-image: none;
          mask-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eicons%20%2F%20icn-close%3C%2Ftitle%3E%3Cdefs%3E%3Cpath%20d%3D%22M9%207.94L16.47.47l1.06%201.06L10.06%209l7.47%207.47-1.06%201.06L9%2010.06l-7.47%207.47-1.06-1.06L7.94%209%20.47%201.53%201.53.47%209%207.94z%22%20id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cpath%20d%3D%22M0%200h18v18H0z%22%20mask%3D%22url(%23b)%22%20fill%3D%22%23434343%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
          mask-repeat: no-repeat;
        }
      }
}

button.om-icon {
    width: $default-icon-width;
    min-width: $default-icon-width;
    height: $default-icon-height;
  }
