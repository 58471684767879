// This file is for variables shared across components.
@import 'palette';
@import 'z_index';

// Font weights
$font-weight-normal: 400;
$font-weight-semibold: 600;

// Body Font
$base-font-size: 1.4rem;
$rem-base: $base-font-size;
$base-line-height: 2.4rem;
$body-font-color: $gray6;
$body-font-style: normal;
$body-font-weight: $font-weight-normal;
$body-font-family: 'Inter', sans-serif;

$pre-font-family: monospace;
$pre-font-size: 1.2rem;

// Spacing
$global-spacing: 0.6rem;

// Layout
$normal-padding: calc(#{$global-spacing} * 2);
$layout-gutter-width: $global-spacing;
$large-layout-gutter-width: $global-spacing * 2;
$mini-layout-gutter-width: $global-spacing / 3;

$body-background-color: $gray1;
$body-width: 1600px;
$body-max-width: 1920px;

$paragraph-line-height: $base-line-height;
$paragraph-margin-bottom: 0.6rem;

$anchor-font-color: $primary;
$anchor-font-color-hover: scale-color($anchor-font-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: underline;

// Badges and Labels
$badge-base-color: $yellow4;
$badge-muted-color: $gray2;
$badge-success-color: $green3;
$badge-base-font-color: $body-font-color;
$badge-warning-background-color: $red5;
$badge-warning-shadow-color: shadow-style(text, red-shadow);
$badge-font-size: $base-font-size;
$badge-padding: $base-font-size * 0.6;
$badge-line-height: $base-line-height / 3;
$badge-border-radius: calc(#{$badge-font-size} + #{$badge-line-height});
$half-badge-padding: $badge-padding / 2;

$label-default-background-color: $yellow4;
$label-min-width: 10px;
$header-background-color-contrasted: $gray1;

// Collapsed Component
$collapse-separator-background-color: $brown1;
$collapse-toggle-background-color: $gray1;
$collapse-toggle-border-color: $gray2;

// Cards
$card-background-color: $white;
$card-title-background-color: $green5;
$card-title-hover-background-color: $green6;
$card-title-color: $white;
$card-title-icon-width: 24px;
$card-title-icon-height: 24px;

// Buttons
$button-border-radius: 2px;
$button-border-width: 1px;
$button-height: 3.2rem;
$button-min-width: 8rem;
$button-padding: $global-spacing;

$button-critical-background-color: $critical;
$button-critical-color: $white;

$button-primary-background-color: $primary;
$button-primary-color: $white;

$button-secondary-background-color: $secondary;
$button-secondary-color: $gray5;

$button-tertiary-background-color: $tertiary;
$button-tertiary-color: $white;

// Forms and Inputs
$form-label-color: $gray6;
$input-background-color: $white;
$input-secondary-background-color: $gray1;
$input-border-color-disabled: $gray3;
$input-border-color-focus: $primary;
$input-border-color-invalid: $critical;
$input-border-color: $gray4;
$input-border-radius: 2px;
$input-border-width: 1px;

$input-font-color: $body-font-color;
$input-font-family: $body-font-family;
$input-font-size: $base-font-size;

$input-padding: $global-spacing / 2;
$small-input-fixed-width: $button-min-width / 2;

// Drowpdowns
$dropdown-background: $white;
$dropdown-distance-from-trigger: $global-spacing;
$dropdown-min-width: 85px;
$dropdown-menu-item-min-width: 100px;

// Icons
$default-icon-color: $primary;
$icon-color: $gray4;
$default-icon-width: 18px;
$default-icon-height: 18px;
$large-icon-width: 24px;
$large-icon-height: 24px;

// Nav
$nav-menu-item-hover: rgba(255, 255, 255, .08);
$nav-menu-item-active: rgba(255, 255, 255, .15);
$nav-dropdown-menu-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, .08);
$nav-dropdown-item-hover: rgba(0, 0, 0, .05);

// Tabs
$tabs-background-color: $gray1;
$tabs-border: 1px solid $gray2;
$tabs-font-color: $anchor-font-color;
$tabs-active-color: $body-font-color;
$tabs-height: 36px;
$tabs-subheading-height: calc(30px + (#{$global-spacing} * 2));
$tabs-action-bar-with-scrollable-sibling-height: $tabs-subheading-height;
$tabs-padding: 1.2rem;
$tabs-header-height: calc(36px + 108px + #{$tabs-height});
$page-header-offset-height: calc(100vh - #{$tabs-header-height} - #{$layout-gutter-width}* 2);
$expected-max-tabs-subheading-height: calc(#{$tabs-subheading-height} * 2);
$tabs-scrollable-with-subheading-height: calc(#{$page-header-offset-height} - #{$expected-max-tabs-subheading-height});
$total-tab-height: calc(#{$expected-max-tabs-subheading-height} + (#{$tabs-action-bar-with-scrollable-sibling-height} + #{$base-line-height}));
$tabs-scrollable-with-subheading-and-action-bar-height: calc(#{$page-header-offset-height} - #{$total-tab-height});
$tabs-empty-padding: $global-spacing * 12;
$tabs-active-background-color: $white;
$tabs-shadow-color: darken($tabs-background-color, 10%);

// om-item
$item-dark-background-color: $gray1;
$item-darker-background-color: $gray2;
$item-warn-background-color: $red3;
$item-border-color: $gray2;
$om-item-padding: $global-spacing ($global-spacing * 2);
$table-row-padding: $om-item-padding;
$section-title-padding: ($global-spacing/2) ($global-spacing * 2);
$container-background-color: $body-background-color;

$inner-list-background-color: $white;
$item-open-max-height: 400px;
$item-option-anchor-color: $tabs-font-color;
$item-selectable-hover-background-color: $blue1;
$item-warn-background-color: $red4;

// Header
$header-font-color: $white;
$header-background-color: $green5;
$primary-heading-shadow: shadow-style(text, green-shadow);

// Timeline Search
$search-filters-font-color: $body-font-color;
$search-filters-active-font-color: $primary;

// Banners
$banner-background: $gray1;
$banner-text: $body-font-color;
$error-background: $red4;
$error-text: $gray6;
$success-background: $green3;
$success-message-color: $success-background;
$warning-background: $yellow3;
$warning-message-color: $yellow6;

// Modal
$modal-background-color: $white;
$modal-backdrop-color: rgba(0, 0, 0, 0.75);
$modal-padding: ($global-spacing * 2);
$modal-width: 560px;
$modal-max-height: calc(80vh - #{$modal-padding});

$table-border: 1px solid $gray2;
$table-heading-background-color: $brown2;
$table-clickable-heading-background-color: darken($table-heading-background-color, 15%);
$table-heading-font-color: $gray6;
$table-row-background-color: $white;
$nested-table-heading-background-color: $gray2;
$nested-table-row-background-color: $gray1;

$dropdown-slim-background-color: $green3;
$dropdown-slim-border-color: $green6;

$fade-out-line-count: 5;

$error-message-color: $input-border-color-invalid;
$progress-message-color: $gray6 !default;
$select-font-size: 1.8rem !default;
$subform-background-color: $blue1;

// Animation
$animation-duration: 0.3s;

// Typography
$header-text-rendering: optimizeLegibility;

$paragraph-font-family: $body-font-family;
$paragraph-font-weight: $font-weight-normal;
$paragraph-font-size: $base-font-size;
$paragraph-margin-bottom: 1.6rem;
$paragraph-text-rendering: optimizeLegibility;
$paragraph-white-space: pre-line;

$list-font-family: $paragraph-font-family;
$list-font-size: $paragraph-font-size;
$list-line-height: $base-line-height;
$list-style-position: outside;

$hr-color: $gray2;
$hr-standard-spacing: $global-spacing / 2;
$hr-large-spacing: $global-spacing * 1.5;
