@import 'variables';
@import 'shadows';

// Typography
%base-typography {
  line-height: $base-line-height;
  font-family: $body-font-family;
  font-size: $base-font-size;
  font-weight: $body-font-weight;
  font-style: $body-font-style;
}

%bold-type {
  font-weight: $font-weight-semibold;
}

%banner {
  margin: $global-spacing 0;
  border-radius: 3px;
  padding: calc(#{$global-spacing} * 2);
  color: $banner-text;
  font-weight: $font-weight-normal;
}

// Buttons
@mixin button-opacity($background-color, $color, $amount) {
  $disabled-font-color: $color;
  @if ($color != $white) {
    $disabled-font-color: transparentize($color, $amount);
  }
  background-color: transparentize($background-color, $amount);
  color: $disabled-font-color;
}

@mixin button-style($background-color, $border-color, $font-color) {
  @extend %base-typography;
  @extend %bold-type;
  border-width: $button-border-width;
  border-style: solid;
  border-radius: $button-border-radius;
  border-color: $border-color;
  background-color: $background-color;
  cursor: pointer;
  padding: 0 $button-padding;
  text-transform: capitalize;
  color: $font-color;
  font-family: inherit;
  font-weight: $font-weight-semibold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;

  &:active {
    background-color: darken($background-color, 15%);
  }

  &:disabled {
    @include button-opacity($background-color, $font-color, 0.4);
    cursor: not-allowed;
  }

  &.-processing {
    @include button-opacity($background-color, $font-color, 0.7);
    cursor: not-allowed;
  }

  &:hover:not(:disabled):not(.-processing),
  &:focus:not(:disabled):not(.-processing) {
    box-shadow: 0 0 2px 0 #5e5e5e;
    background-color: darken($background-color, 5%);
  }

  .om-icon {
    background-color: $font-color;
  }
}

%primary-button-style {
  @include button-style($button-primary-background-color, transparent, $button-primary-color);
}

%secondary-button-style {
  @include button-style($button-secondary-background-color, transparent, $button-secondary-color);
}

%tertiary-button-style {
  @include button-style($button-tertiary-background-color, transparent, $button-tertiary-color);
}

%critical-button-style {
  @include button-style($button-critical-background-color, transparent, $button-critical-color);
}

%flat-button-style {
  @include button-style($white, transparent, $primary);
  background-color: inherit;
}
