.action-bar {
  border-top: 1px solid $hr-color;
  background-color: $brown1;
  padding: $global-spacing ($global-spacing * 2);

  &.-warning {
    background-color: $warning-background;
  }

  &.-error {
    background-color: $error-background;
    color: $error-text;
  }

  &.-with-scrollable-sibling {
    min-height: $tabs-action-bar-with-scrollable-sibling-height;
  }

  .perform-now {
    margin-left: $global-spacing;
  }
}
