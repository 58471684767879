// bootstrap overrides
// use a 16-column grid system rather than bootstrap default 12-col grid
// https://www.pivotaltracker.com/n/projects/2407382/stories/171478788
$grid-columns: 16;
$grid-gutter-width: 16px;
$container-max-widths: (
  sm: 540px, // default
  md: 720px, // default
  lg: 960px, // default
  xl: 1392px // custom
);

// $spacer is the base bootstrap variable used to compute all their spacing classes
// it's normally defined as 1rem (which assumes a browser base default of 16px)
$spacer: 1.3rem;

// $font-size-base is the base bootstrap variable used to compute all their font sizes
// it's normally defined as 1rem (which assumes a browser base default of 16px)
$font-size-base: 1.3rem;
