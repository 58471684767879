
$white: #ffffff;
$gray1: #f6f6f6;
$gray2: #d4d4d4;
$gray3: #9c9c9c;
$gray4: #747474;
$gray5: #434343;
$gray6: #111111;
$gray7: #e8e8e8;
$gray8: #cfcfcf;
$brown1: #f1eeeb;
$brown2: #ded7cf;
$brown3: #ccc1b7;
$brown4: #bcaea0;
$brown5: #a59483;
$brown6: #847668;
$blue1: #e3e7ef;
$blue2: #c7d1e5;
$blue3: #b0bedb;
$blue4: #879dca;
$blue5: #4570c6;
$blue6: #37599e;
$green1: #e7eeec;
$green2: #bfd6d2;
$green3: #98c1bb;
$green4: #72afa6;
$green5: #1e988b;
$green6: #18796f;
$yellow1: #fef4e4;
$yellow2: #fce3b9;
$yellow3: #fad490;
$yellow4: #f8c568;
$yellow5: #f5b21e;
$yellow6: #a67d12;
$red1: #fbeade;
$red2: #f4c7ae;
$red3: #eda580;
$red4: #e77f53;
$red5: #e15928;
$red6: #b44720;

// Color Aliases
$primary: $blue5;
$secondary: $brown2;
$tertiary: $green5;
$critical: $red6;

$om-jade: #005450;

// External colors
$ng-select-disabled: #999;
$browser-default-border: #ccc;

$bootstrap-dark-button: #212529;
