@import './scss/bootstrap-overrides';

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/scss/bootstrap';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import './scss/variables';
@import './scss/action-bar';
@import './scss/button';
@import './scss/icons';
@import './scss/modal';

@import url('https://rsms.me/inter/inter.css');

html {
  // convert root font size (16px) into base 10 to simplify rem values
  font-size: 62.5%;
}

body {
  font-family: $body-font-family;
  font-size: $font-size-base;
  background-color: $gray1;

  @supports (font-variation-settings: normal) {
    font-family: 'Inter var', sans-serif;
  }

  .flex-1 {
    flex: 1;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    &.ng-option-selected.ng-option-marked {
      background-color: $blue2;
    }
    &.ng-option-selected {
      background-color: $blue2;
    }
    &.ng-option-marked {
      background-color: $blue1;
    }
  }
}
